.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;

  .logo {
    width: 100px;
    margin-bottom: 20px;
  }

  h2 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
    color: #666;
  }

  form {
    width: 100%;
  }

  .auth-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
  }

  .auth-button {
    width: 100%;
    padding: 10px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
    height: 40px; // Match the height of input fields
    box-sizing: border-box;

    &:hover {
      background-color: #333;
    }
  }

  .google-button {
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px; // Match the height of input fields

    .google-icon {
      margin-right: 10px;
    }

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .auth-link {
    margin-top: 20px;
    font-size: 14px;

    a {
      color: #007bff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}